import React from "react";
import Menu from "../../components/Menu/Menu";
import Tagline from "../../features/Tagline/Tagline";
import Portfolio from "../../features/Portfolio/Portfolio";
import Process from "../../features/Process/Process";
import Offers from "../../features/Offers/Offers";
import Testimonials from "../../features/Testimonials/Testimonials";
import CTA from "../../features/CTA/CTA";
import Footer from "../../features/Footer/Footer";

export default function Tunnel() {
  return (
    <div className="flex flex-col gap-16 bg-[#F7F4F0] w-max max-w-[100vw]">
      <Menu />
      <Tagline />
      <Portfolio />
      <Process />
      <Offers />
      {/* <Testimonials /> */}
      <CTA />
      <Footer />
    </div>
  );
}
